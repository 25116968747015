header {
  @include media('<tablet') {
    padding-top: em(9px, $navbar-font-size);
    padding-bottom: em(9px, $navbar-font-size);
    padding-left: 4.7%;
    padding-right: 4.7%;
  }
  a.brandimg {
    @include media('<tablet') {
      $size: em(20px, $navbar-font-size);
      margin: 0 auto;
      width: $size;
      height: $size;
      display: block;
    }
  }

  img.logo {
    @include media('<tablet') {
      margin: 0;
    }
  }
  .logo-big {
    @include media('<tablet') {
      display: none;
    }
  }
}

a.mobile-action {
  display: none;
  float: right;
  cursor: pointer;

  @include media('<tablet') {
    display: block;
  }

  color: $light-grey;
  font-size: em(16px, $navbar-font-size);

  &.disabled {
    color: $topnav-link-hover-color;
    cursor: default;
  }
}

a#open-menu {
  cursor: pointer;
  i {
    font-size: em(8.5px, $navbar-font-size);
    line-height: em(26px, $navbar-font-size);
    vertical-align: text-top;
    margin-right: em(20px, $navbar-font-size);
  }
}

a#close-menu {
  cursor: pointer;
  padding-left: $content-padding - 4.7%;
  i {
    font-size: em(14px, $navbar-font-size);
  }
}

a.mobile-action--left {
  float: left;
}
a.mobile-action--right {
  float: right;
}
a#close-menu {
  display: none;
}
