@import '../../lib/bourbon/app/assets/stylesheets/bourbon';
@import '../../lib/include-media/dist/include-media';

@import 'modules/font_morgenwerk';
@import 'settings';

$breakpoints: (phone: 28em, tablet: 53em, desktop: 64em, large: 74em);

// import custom modules
@import 'modules/type';
@import 'modules/forms';
@import 'modules/layout';
@import 'modules/button';
@import 'modules/navbar';
@import 'modules/navbar-mobile';
@import 'modules/header';
@import 'modules/header-mobile';
@import 'modules/static_page';
@import 'modules/parallax';
@import 'modules/index';
@import 'modules/parallax-mobile';
@import 'modules/technologies';
@import 'modules/slick';
@import 'modules/watches';
@import 'modules/overlay';
@import 'modules/cart';
@import 'modules/confirmation';
@import 'modules/footer';
@import 'modules/radio';
