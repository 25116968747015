.map-box {
  font-family: $base-font-family;
}

#radio {
  opacity: 1;
  > * {
    opacity: 0;
  }
}

#cell{
  opacity: 1;
  > * {
    opacity: 0;
  }
}

#gps {
  opacity: 1;
  > * {
    opacity: 0;
  }
}

.map-actions {
  font-size: rem($map-action-font-size);
  text-align: center;

  .rhombus {
    @include transition(opacity 0.2 ease-in);

    font-size: rem($map-action-font-size);
    margin-right: em(15px, $map-action-font-size);
    width: em(68px, $map-action-font-size);
    height: em(68px, $map-action-font-size);
    cursor: pointer;
    opacity: 1;
    display: inline-table;

    &:hover {
      opacity: 0.8 !important;
    }

    &:last-child {
      top: -0.38em;
    }
  }

  .rhombus__content {
    padding: 0;

    font-family: $base-font-family;
    p {
      padding: 0.1em;
      line-height: em(13.5px, $map-action-font-size);
      margin: 0;
      color: $darker-grey;
    }
  }
}

.map-actions + p {
  font-family: $base-font-family;
}
.map-actions .rhombus__content p {
  font-size: 1.0em;
}

#world-map {
  margin-top: rem(15px);
  width: 100%;

  svg {
    width: 100%;
    height: auto;
  }
}

#maparea-description {
  color: $light-grey;
}
