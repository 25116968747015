header {
  background-image: linear-gradient($dark-grey, $darker-grey);
  padding: 0 4.7% 0 $content-padding;
  font-size: rem($navbar-font-size);

  width: 100%;
  position: fixed;
  top: 0;
  z-index: 101;

  box-shadow: $box-shadow;

  .logo-big {
    position: fixed;
    z-index: 1000;
    left: $content-padding;

    width: em(168px, $navbar-font-size);
    border-radius: 0 0 0.75em 0.75em;
    box-shadow: $box-shadow;
  }

  .logo {
    $size: em(30px, $navbar-font-size);
    width: $size;
    margin-left: (em(168px, $navbar-font-size) - $size) / 2;
    margin-top: (em($header-height - 1px, $navbar-font-size) - $size) / 2;
  }
}

@include media('>=tablet') {
  .home header .logo-big {
    display: inline-block;
  }
}


.dropdown {
  $large-screen: em(640) !default;
  $dropdown-distance-from-menu: 3.8em;

  p {
    margin: 0;
  }

  ul {
    background-color: $darker-grey;
    padding: 0;
    margin: 0;
    box-shadow: $box-shadow;
  }
  ul li {
    display: inline-block;
    text-align: center;
    margin: 0;
    padding: 1em;
    width: 100%;

    &:hover {
      background-color: lighten($darker-grey, 5%);
    }
  }

  .dropdown-container {
    display: inline-block;
    position: relative;
    text-align: center;
  }

  .dropdown-button {
    @include transition (all 0.2s ease-in-out);
    cursor: pointer;
    position: relative;

    &:hover {
      color: $light-grey-hover;
    }
  }

  .dropdown-button:after {
    display: block;
    position: absolute;
  }

  .dropdown-menu {
    @include transition (all 0.2s ease-in-out);
    cursor: pointer;
    display: none;
    overflow: show;
    position: absolute;
    text-align: left;
    top: $dropdown-distance-from-menu;
    z-index: 99999;
  }

  .show-menu {
    display: block;
  }
}
