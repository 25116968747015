.mw_check {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  margin-right: 1em;
  margin-bottom: 0.2em;
  padding: 0;
  width: 1em;
  height: 1em;
  border: none;
  cursor: pointer;
  background-color: $dark-grey;
}

.mw_check.checked {
  background-color: $dark-green;
}
.mw_check.disabled {
  cursor: default;
}
.mw_check.checked.disabled {
}



/*======================================
  Selectric
======================================*/
.selectric-wrapper {
  display: inline-block;
  margin-left: 1em;
  position: relative;
  cursor: pointer;

  p {
    margin: 0;
  }
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: none;
  background: $dark-grey;
  color: $light-grey;
  position: relative;
}
.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 1em;
  padding-right: 3em;
}
.selectric .button {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 2em;
  color: $dark-grey;
  text-align: center;
}
.selectric .button:after {
  content: "\e811";
  font-family: "morgenwerk";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: $light-grey;
  font-size: 1.2em;
  font-weight: 100;
}

.selectric-open {
  z-index: 9999;
}
.selectric-open .selectric-items {
  display: block;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}
.selectric-hide-select select {
  position: absolute;
  left: -100%;
  display: none;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 0.2em;
  background: $dark-grey;
  z-index: -1;
  box-shadow: $box-shadow;
}
.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}
.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}
.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 1.5em;
}
.selectric-items li {
  display: block;
  padding: 8px;
  cursor: pointer;
}
.selectric-items li.selected {
  color: $light-grey-hover;
}
.selectric-items li:hover {
  color: $light-grey-hover;
  background-color: lighten($dark-grey, 2%);
}


/*======================================
 Formstone - Number
======================================*/
.fs-number {
  position: relative;
  overflow: hidden;
}

.fs-number,
.fs-number:after,
.fs-number:before,
.fs-number *,
.fs-number *:after,
.fs-number *:before {
  box-sizing: border-box;
  -webkit-transition: none;
          transition: none;
}
.fs-number-element {
  width: 100%;
  background: $lighter-grey;
  color: $darker-grey;
  overflow: hidden;
  -moz-appearance: textfield;
}
.fs-number-element::-webkit-inner-spin-button,
.fs-number-element::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.fs-number-element::-ms-clear {
  display: none;
}
.fs-number-element:focus {
  background-color: #ffffff;
}
.fs-number-arrow {
  width: em(16.4px, $cart-font-size);
  height: 50%;
  position: absolute;
  right: 0;
  z-index: 1;
  background: $lighter-grey;
  color: $lighter-grey;
  border: none;
  border-left: $base-border-width solid $darker-grey;

  cursor: pointer;
  display: block;
  overflow: hidden;
}
.fs-number-arrow:focus {
  outline: none;
}
.fs-number-arrow:after {
  content: "\e813";
  font-family: "morgenwerk";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: $darker-grey;
  font-size: 1.2em;
  line-height: em(15.4px, $cart-font-size);
  font-weight: 100;
}
.fs-number-arrow.fs-number-up {
  top: 0;
}
.fs-number-arrow.fs-number-up:after {
}
.fs-number-arrow.fs-number-down {
  bottom: 0;
  border-top: $base-border-width solid $darker-grey;
}
.fs-number-arrow.fs-number-down:after {
  content: "\e811";
}
