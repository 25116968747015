body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-feature-settings: "kern", "liga", "pnum";
  font-size: $base-font-size;
  font-weight: 300;
  line-height: $base-line-height;

  @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      font-weight: 100;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading-font-color;
  font-family: $heading-font-family;
  font-size: $base-heading-font-size;
  font-weight: 300;
  line-height: $heading-line-height;
  margin: 0;
  text-transform: uppercase;

  @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      font-weight: 100;
  }
}

h1 {
  margin-bottom: 1em;
}


p {
  color: $base-font-color;
  margin: 0;
  margin-bottom: 1em;
}

a {
  color: $action-color;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    color: shade($action-color, 25%);
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

address {
  padding-bottom: 1.25em;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}
