.text-box {
  font-size: $parallax-font-size;
  position: relative;
  top: 0;
  z-index: 5;
  padding: em(70px, $parallax-font-size) 25%;
  text-align: center;

  background-color: $lighter-grey;
  box-shadow: $box-shadow;

  &, h2 {
    font-family: garamond-fb-display, serif;
  }

  @include media('<tablet') {
    padding-left: $content-padding;
    padding-right: $content-padding;
  }

  h2, p {
    color: #333;
    font-weight: 600;
  }

  h2 {
    font-size: em(40px, $parallax-font-size);
    text-transform: none;
  }

  p {
    font-size: em(20px, $parallax-font-size);
    margin: 0;
    margin-top: 1em;
  }
}

.text-box--dark {
  background-color: #333;

  p, h2 {
    color: white;
    font-weight: 500;
  }
}

.text-box--full {
  padding: em(50px, $parallax-font-size) $content-padding;
  @include media('<tablet') {
    padding-left: 0;
    padding-right: 0;
  }
}

.text-box__footer {
  font-family: $base-font-family;
  a {
    color: $black;
    margin-left: 2em;
    &:hover {
      color: $darker-grey;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}

.text-box--dark .text-box__footer {
  a {
    color: $white;
    &:hover {
      color: $lighter-grey;
    }
  }
}
