@media (max-device-width: 1024px), (orientation: portrait) {
  .parallax {
    max-height: none;

    i.icon-scroll-up,
    i.icon-scroll-down {
      position: relative;
      bottom: auto;
      display: inline-block;
      margin-bottom: 1em;
    }

    i.icon-scroll-down {
      display: none;
    }
  }
  .parallax__background {
    top: 0 !important;
    background-position: right center;
    height: em(370px, $parallax-font-size);
    max-height: calc(70vh - 2.5rem);
  }

  .parallax--right {
    .parallax__background {
      background-position: left center;
    }
  }


  .parallax__content {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
    padding: em(22px, $parallax-font-size);
    text-align: center;
    width: auto;

    h2 {
      font-size: em(32px, $parallax-font-size);
      color: $light-grey;
    }

    p {
      font-size: em(24px, $parallax-font-size);
      line-height: 1.2;
      color: $light-grey;
    }
  }
  .parallax__footer {
    font-size: em(16px, $parallax-font-size) !important;
    text-align: center !important;

    a {
      color: $light-grey !important;

      &:hover {
        color: $white !important;
      }
    }

    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    top: auto;
  }

  .parallax__model {
    display: none;
  }

  .text-box {
    padding: em(17px, $parallax-font-size) em(13px, $parallax-font-size);

    h2 {
      font-size: em(26px, $parallax-font-size);
    }

    p {
      font-size: em(22px, $parallax-font-size);
      line-height: 1.2;
    }
    .text-box__footer {
      margin-top: 0;
      text-align: center !important;
      font-size: em(16px, $parallax-font-size);
    }
  }

  .map-actions {
    p {
      font-size: 1.0em;
    }
    & + p {
      font-size: 0.8em;
    }
  }
}
