$cart-color: $lighter-grey;
$triangle-cart-size: 1.5em;

#overlay {
  display: none;
  position: absolute;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  top: 0;
  z-index: 10;
  width: 100%;

  @include media('<tablet') {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &.open {
    display: block;
  }

  > div {
    display: none;

    &.open {
      display: block;
    }

    .content {
      z-index: 17;
    }

    position: relative;
    z-index: 15;

    top: 5rem;
    background-color: $darker-grey;
    min-height: 10rem;
    box-shadow: $box-shadow;
  }
}

#cart,
#newsletter {
  float: right;
}

#newsletter {
  position: fixed !important;
  right: 1.2rem;

  @include media('<tablet') {
    position: block !important;
    right: 0.5rem;
    left: 0.5rem;
    width: auto;
  }

  @include media('<=tablet') {
    .cart__triangle {
      display: none;
    }
  }
}

#terms {
  width: auto;
  margin-right: rem(421.9px) + 1rem;
  padding: 1em;

  article {
    padding: 0;
    width: auto;
  }
}

nav.terms-nav {
  border-bottom: $base-border;
  margin-bottom: 1em;

  ul {
    text-align: left;
    margin: 0;
    margin-top: 1em;
    padding: 0;

    li {
      text-transform: uppercase;
      list-style: none;
      display: inline-block;
      margin-right: 3rem;

      a {
        color: $lighter-grey;

        &:hover, &.active {
          color: $light-grey;
        }
      }
    }
  }
}
