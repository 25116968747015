// custom variables
$img-path: '../img';

// Colors
$white: #fff;
$black: #000;
$green: rgb(86, 180, 0);
$dark-green: #56b400;
$red: rgb(220, 0, 0);
$mud-brown: #5b1515;
$orange: rgb(255, 147, 38);
$blue: rgb(0, 190, 255);
$blue-hover: rgb(77, 210, 255);
$lighter-grey: #dcdcdc;
$light-grey: #999;
$light-grey-hover: #666;
$dark-grey: #444;
$darker-grey: #333;

// Font Colors
$base-font-color: $lighter-grey;
$heading-font-color: $lighter-grey;
$action-color: $orange;
$cart-color: $light-grey;


// Typography
$base-font-family: "museo-sans", sans-serif;
$heading-font-family: $base-font-family;

$header-height: 59.1px;
$header-height-mobile: 40px;
$navbar-font-size: 13.5px;
$parallax-font-size: 22px;
$technologies-font-size: 16px;
$footer-font-size: $navbar-font-size;
$watches-slider-font-size: 11.3px;
$amount-font-size: 18.3px;
$showcase-font-size: 11.3px;
$cart-font-size: 11.3px;
$static-font-size: 13.5px;
$confirmation-font-size: 17px;
$map-action-font-size: 14px;


// Font Sizes
$base-font-size: 1.4em;
$base-heading-font-size: 1em;

// Line height
$base-line-height: 1.0em;
$heading-line-height: 1.2em;

// Other Sizes
$content-padding: 9.9%;
$base-border-radius: rem(1.0px);
$base-spacing: 1rem;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;


$box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
$text-shadow: 0px 1px 7px rgba(0, 0, 0, 0.5);

// Border
$base-border-width: rem(1.5px);
$base-border-color: $light-grey;
$base-border: $base-border-width solid $base-border-color;

// Background Colors
$base-background-color: $darker-grey;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;
