article {
  font-size: rem($static-font-size);
  font-weight: 100;
  padding: 2.6rem $content-padding 2em $content-padding;
  width: 65%;

  h1, h2, h3 {
    font-size: em(14.5px, $static-font-size);
  }

  &, h1, h2, h3, p {
    color: $light-grey;
  }

  @include media('<=large') {
    width: 100%;
  }

}

.content-box {
  width: auto;

  section {
    column-count: 2;
    column-gap: 4em;

    @include media('<desktop') {
      column-count: 1;
    }
  }
}
