.watches {
  @include clearfix;
  font-size: rem($watches-slider-font-size);
  line-height: 1.5;

  position: relative;
  background-color: $lighter-grey;
  padding: 0;
}

.watches__watch {
  display: table;
  position: relative;
  width: 100%;
  height: em(273.4px, $watches-slider-font-size);

  img {
    @include transition(opacity 0.2s ease-in);
    position: fixed;
    margin: 0 auto;
    width: auto;
    height: auto;
    max-height: em(273.4px, $watches-slider-font-size);
    z-index: 1;
  }

  &:hover img {
    opacity: 0.2;
  }


  &:hover .watches__watch__hover {
    opacity: 1;
  }
}

.watches__watch__hover {
  @include transition(opacity 0.2s ease-in);

  position: relative;
  display: table-cell;
  vertical-align: middle;
  opacity: 0;
  z-index: 2;
  text-align: center;

  > small {
    display: block;
    font-size: em(8.4px, $watches-slider-font-size);
    color: $darker-grey;
    line-height: 1em;
  }
  > h2 {
    color: $darker-grey;
    font-size: em(22.5px, $watches-slider-font-size);

    + small {
      text-transform: uppercase;
    }
  }
  .btn {
    margin: 1.5em 0;
  }
  .rhombus {
    margin: 0 auto;
  }
}


.rhombus {
  background-repeat: no-repeat;
  background-image: url(#{$img-path}/raute-dark.svg);
  background-size: 100%;
  font-size: rem($amount-font-size);
  position: relative;
  display: table;
  width: em(70px, $amount-font-size);
  height: em(70px, $amount-font-size);
}
.rhombus--red {
  background-image: url(#{$img-path}/raute-red.svg);
}
.rhombus--blue {
  background-image: url(#{$img-path}/raute-blue.svg);
}
.rhombus--orange {
  background-image: url(#{$img-path}/raute-orange.svg);
}
.rhombus--light {
  background-image: url(#{$img-path}/raute.svg);

  &,
  p,
  .rhombus__content h2 {
    color: $darker-grey;
  }
}

.rhombus__content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  color: $white;
  padding-top: 0.2em;

  p.small {
    margin: 0;
    font-size: em(8.3px, $amount-font-size);
    line-height: 1.2em;
  }
  h2 {
    font-size: em(17px, $amount-font-size);
    line-height: 1em;
  }
}

.rhombus--red {
  .rhombus__content h2 {
    font-size: em(16px, $amount-font-size);
  }
}

.rhombus--seperator {
  background-image: url(#{$img-path}/raute.svg);
  margin: rem(34.4px) 0;
  width: em(204.6px, $amount-font-size);
  height: em(204.6px, $amount-font-size);

  .rhombus__content {
    h2 {
      color: $darker-grey;
      font-size: em(81px, $amount-font-size);
    }
  }
}

.seperator__title {
  color: $light-grey;
  font-size: em(70px, $amount-font-size);
  font-weight: 100;
  padding-top: 0.75em;
}

.seperator {
  position: relative;
  background-color: $darker-grey;
  padding: rem(45px) rem(105px);
  box-shadow: $box-shadow;

  @include media('<tablet') {
    padding-left: 0;
    padding-right: 0;
    .rhombus--seperator {
      margin: rem(34.4px) auto;
    }
  }
}

.seperator--shadow {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
  z-index: 300;
}

.showcase {
  @include clearfix;
  position: relative;
  font-size: rem($showcase-font-size);
  background: $lighter-grey;
  color: $darker-grey;
  padding-bottom: 2em;

  .watch-anker {
    position: absolute;
    top: rem(-$header-height);

    @include media('<desktop') {
      top: rem(-$header-height-mobile);
    }
  }
}

.showcase__headline {
  margin: em(25) 0;
  color: $dark-grey;

  > h2 {
    color: $darker-grey;
    font-size: em(33.8px, $showcase-font-size);
    line-height: 1em;
    float: right;
    text-align: right;
    margin-bottom: 2em;
    text-transform: none;
  }

  .rhombus {
    float: left;
  }

  img.good_design {
    float: left;
    height: em(70px, $showcase-font-size);
    margin-left: 3em;
  }
}

.showcase {
  .single {
    width: 55%;
  }
  .details {
    width: 45%;
  }
  .single, .details {
    float: left;
    position: relative;

    @include media('<desktop') {
      width: 100%;
      float: none;
      padding-left: $content-padding;
      padding-right: $content-padding;
    }
  }
  .details {
    padding-top: em(50);
    padding-left: 2.8%;
    padding-right: $content-padding;
  }
  @include media('<desktop') {
    .details {
      padding-top: 0;
    }
  }

  .slider {
    position: relative;
    .slick-slider {
      margin: 0;
    }

    .slick-slide {
      max-height: em(766px, $showcase-font-size);
      overflow: hidden;

      &:first-child img {
        width: auto;
        margin: 0 auto;
      }
    }

    img {
      width: 100%;
    }

    @include media('<desktop') {
      padding-top: 10%;
    }
  }


  table {
    width: 100%;
    line-height: 1.3em;

    tbody {
      border-top: $base-border;
      border-color: $darker-grey;

      tr td:first-child {
        width: 40%;
      }

      tr {
        border-bottom: $base-border;
        border-color: $darker-grey;
      }
      td {
        padding-top: 0.2em;
        padding-bottom: 1em;
        vertical-align: top;
      }
      tr.no-space {
        border-bottom: 0;
        td {
          padding-bottom: 0;
        }
        + tr td {
          padding-top: 0;
        }
      }
    }

    tfoot {
      td {
        padding-top: 0.4em;
      }
    }
  }
}

.showcase__actions .btn {
  float: right;
  padding: em(10px) em(15px);
}
.showcase__actions .btn--single {
  padding: 1em 1.5em;
  font-size: em(18px, $showcase-font-size);
}
.showcase__actions .btn--soldout {
  border: none;
  color: $white;
  background-color: $mud-brown;
}

.showcase__actions > * {
  width: 49.5%;
  display: inline-block;
  margin-top: em(69.3px / 3 * 2, $showcase-font-size);
  text-align: center;
  text-transform: uppercase;

  @include media('<tablet') {
    &:first-child {
      margin-top: 2em;
    }

    margin-top: 0.5em;
    width: 100%;
    float: none !important;
  }
}

.frame {
  padding: em(10px) em(15px);
  background-color: transparent;
  color: $darker-grey;
  border: $base-border;
  border-color: $darker-grey;
  font-size: 1em;
}

.frame--delivery {
  margin-top: 0.5em;
  padding: 0;
  color: $green;
  text-align: center;
  text-transform: uppercase;
}
.frame--delivery--grey {
  color: $light-grey;
}

.showcase--dark {
  background-color: $darker-grey;

  .single .slick-dots li button {
    &:before {
      color: $light-grey;
    }
  }

  &, .showcase__actions .frame, h2 {
    color: $light-grey;
  }

  .amount {
    .amount__box {
      background-color: $light-grey;
    }
    .amount__content {
      &, h2 {
        color: $darker-grey;
      }
    }
  }
  table {
    tbody {
      border-color: $light-grey;
      tr {
        border-color: $light-grey;
      }
    }
  }

  .btn.btn--inverse {
    color: $darker-grey;
    background-color: $light-grey;
    border-color: $light-grey;

    &:hover {
      background-color: $light-grey-hover;
      border-color: $light-grey-hover;
    }
  }
  .frame {
    border-color: $light-grey;
  }
}
