$cart-content-padding: em(27.5px, $cart-font-size);
$image-box-size: em(171.6px, $cart-font-size);

.no-border {
  border: none !important;
  margin-bottom: 0;
}

.full-error {
  background-color: $orange;
  &, a, p {
    color: $darker-grey !important;
  }
}

.cart__items-error {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 17;

  .btn {
    color: $darker-grey !important;
    text-align: center;
  }
}
.cart__triangle {
  $triangle-cart-size: 1.6em;

  display: block;
  z-index: 14;
  position: absolute;
  top: #{-$triangle-cart-size / 2.15};
  margin-top: 0;
  right: 11%;
  @include triangle($triangle-cart-size, $darker-grey, up);
}

.cart__items-error__content {
  @extend .full-error;

  padding: em(25px, $cart-font-size);
  padding-bottom: 4em;
  font-size: em(16.7px, $cart-font-size);
  line-height: 1.1em;

  ul {
    padding: 0;
    li {
      list-style: none;
    }
  }
}

.cart__content__inner {
  border: none !important;
  position: relative;

  .paypal-notice {
    border-bottom: none !important;

    &.hidden {
      display: inline-block;
      color: $darker-grey;
    }
  }
}

.error-info {
  @extend .full-error;

  border: none !important;
  padding: 0 $cart-content-padding !important;
  text-transform: uppercase;
  line-height: em(27px, $cart-font-size);
}

.cart {
  font-size: rem($cart-font-size);
  line-height: 1.2em;
  width: em(421.9px, $cart-font-size);

  &, p {
    color: $light-grey;
  }

  .errorlist {
    display: none;
  }

  @include media('<tablet') {
    width: 100%;

    .cart__content__row {
      padding-left: 10%;
    }
  }

  .back-to-cart {
    padding-bottom: 0 !important;
    padding-left: 1.5em;
    font-size: em(13.5px, $cart-font-size);
    text-decoration: none;
    border: none !important;
    line-height: 4em;

    i {
      font-size: em(25px, $cart-font-size);
      position: relative;
      top: 0.15em;
    }
  }
}

.cart__content {
  form > *,
  .cart__content__inner > *, {
    border-bottom: $base-border;
    border-color: $light-grey-hover;
  }

  form > p,
  .cart__content__inner > p{
    padding: 1.5em $cart-content-padding;
  }

  a {
    color: $light-grey;

    &:hover {
      color: $light-grey-hover;
    }
  }

  .selectric-wrapper {
    top: 0.3em;
    min-width: em(160px, $cart-font-size);
    line-height: 1.4em;
  }

  dl {
    @include clearfix;

    margin: 0;
    padding: 1.5em $cart-content-padding;
    text-transform: uppercase;

    dt, dd {
      padding-top: 2.1em;

      &:first-child {
        &, + dd {
          padding-top: 0;
        }
      }
    }

    dt {
      clear: both;
      float: left;
    }
    dd {
      float: right;
    }

    &.total {
      padding-bottom: em(57.9px, $cart-font-size);

      dd {
        font-size: em(16.9px, $cart-font-size);
        text-align: right;
        .watchmodels-note {
          display: block;
          font-size: 0.70625rem;
          margin-top: 5px;
        }
      }
    }
  }

  .btn {
    width: 100%;
    padding: 0;
    text-transform: uppercase;
    font-size: em(15.1px, $cart-font-size);
    line-height: em(54.4px, 15.1px);

    &, &:active, &:focus {
      border: none;
    }

    &.disabled {
      cursor: default;
      background-color: $dark-grey;
      color: $darker-grey;
    }
  }
}

.cart__content__headline {
  color: $light-grey;
  text-align: center;
  font-size: em(15.2px, $cart-font-size);

  h2 {
    line-height: em(53.2px, 15.2px);
    text-transform: uppercase;
  }
}

.cart__content__row {
  padding: 1em $cart-content-padding 1em em(97.4px, $cart-font-size);

  a {
    text-decoration: underline;
  }

  > div {  // nested rows
    margin-top: 1em;

    &:first-child {
      margin-top: 0;
    }
  }
  &.error {
    @extend .full-error;
  }
}

.cart__content__field {
  $height: 53px;
  position: relative;

  input {
    width: 100%;
    color: $light-grey-hover;
    background-color: $darker-grey;
    border: none;
    padding: 0;
    padding-left: em(28.9px, $cart-font-size);
    font-size: em(15.1px, $cart-font-size);
    line-height: em($height, 15.1px);

    &:focus {
      border: none;
      outline: none;
      background-color: $dark-grey;
    }

    @include placeholder {
      color: $light-grey-hover;
      text-transform: uppercase;
    }
  }

  &.error:after {
    $dot-size: em(11.4px, $cart-font-size);

    display: block;
    content: " ";
    position: absolute;
    top: (em($height, $cart-font-size) / 2) - ($dot-size / 2);
    right: 1.0em;

    border-radius: 50%;
    width: $dot-size;
    height: $dot-size;
    background-color: $orange;
  }
}
.cart__content__field--country {
  background-color: $dark-grey;

  .selectric .button {
    right: 1.5em;
  }

  .selectric-wrapper {
    display: block;
    top: 0;
    margin: 0;
    width: 100%;
    font-size: em(15.1px, $cart-font-size);
    line-height: em(53px, 15.1px);
  }
  p {
    text-transform: uppercase;
  }
  .selectric > p,
  .selectric-items ul li {
    padding-left: em(28.9px, $cart-font-size);
  }
  .selectric-open .selectric-items {
    width: 100% !important;
  }
}

.cart__item {
  @include clearfix;
}

.cart__item__box {
  float: left;
  background-color: $light-grey-hover;
  width: $image-box-size;
  height: $image-box-size;
  text-align: center;

  img {
    $height: em(130px, $cart-font-size);

    display: inline-block;
    height: $height;
    margin-top: ($image-box-size - $height) / 2;
  }
}

.cart__item__info {
  $padding: em(28px, $cart-font-size);
  position: relative;
  margin-left: $image-box-size;
  height: $image-box-size;
  padding: $padding;

  p.price {
    float: right;
  }

  > input,
  > .fs-number {
    position: absolute;
    background-color: $lighter-grey;
    bottom: $padding;
    left: $padding;
    color: $darker-grey;
    line-height: em(33.8px, $cart-font-size);
    width: em(33.8px + 16.3px, $cart-font-size);
  }
  input {
    text-align: center;
    border: none;
    line-height: em(33.8px, $cart-font-size);
  }
  > .fs-number {
    padding: 0;

    .fs-number-element {
      width: em(33.8px, $cart-font-size);
    }
  }

  .cart-remove {
    position: absolute;
    bottom: $padding;
    right: $padding - 1em;
  }
}


.cart__loading {
  display: none;
  z-index: 20;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba($black, 0.8);

  img {
    $size: rem(50px);

    opacity: 0.6;
    position: absolute;
    width: $size;
    height: $size;
    top: 50%;
    left: 50%;
    margin-left: $size / -2;
    margin-top: $size / -2;
  }
}
.cart.loading .cart__loading {
  display: block;
}

.cart__content__inner {
  .cart__taxnote {
    margin-bottom: 0;
  }
}
.cart__coupon {
  input.coupon {
    width: 100%;
    color: #333333;
    font-size: 12px;
    background-color: #dddddd;
    border: none;
    padding: 10px 40px 10px 30px;
    &.valid,
    &.invalid {
      background-image: url('/static/admin/img/icon-yes.gif');
      background-repeat: no-repeat;
      background-position: center right 12px;
      background-size: 12px;
    }
    &.invalid {
      background-image: url('/static/admin/img/icon-no.gif');
    }
  }
  &.error input.coupon {
    border: 1px solid $orange;
  }
  .errorlist {
    display: block;
    list-style-type: none;
    color: $orange;
  }
}
.cart__discount {
  display: none;
  &.visible {
    display: block;
  }
}
