footer {
  font-size: rem($footer-font-size);
  padding: em(100px, $footer-font-size) 5% em(30px, $footer-font-size) 5%;
  width: 100%;
  display: block;
  z-index: 10;
  box-shadow: $box-shadow;
}

.social {
  float: right;
}
.rhombus--social {
  $size: em(55.8px, $footer-font-size);

  font-size: rem($footer-font-size);
  background-image: url(#{$img-path}/raute-light-dark.svg) !important;
  float: right;
  margin-top: -$size / 2;
  margin-left: em(22.8px, $footer-font-size);
  width: $size;
  height: $size;
  i {
    color: $lighter-grey;
    font-size: em(18.1px, $footer-font-size);
  }
}

.imprint {
  margin: 0;
  margin-top: 5em;
  color: $dark-grey;
  text-align: center;
  font-size: em(10.7px, $footer-font-size);
}

@include media('<desktop') {
  .social {
    float: none;
    text-align: center;
    margin-bottom: 2em;

    a:first-child .rhombus {
      margin-left: 0;
    }
  }

  .rhombus--social {
    float: none;
    display: inline-table;
  }
}
