.slick-slider {
  margin: 0 em(55px);
  padding: 0;
}

.slick-prev,
.slick-next {
  $arrow-height: rem(43.5px);

  position: absolute;
  z-index: 100;
  display: block;
  cursor: pointer;
  background: transparent;
  color: transparentize($dark-grey, 0.2);
  padding: 0;
  border: none;
  outline: none;

  top: 50%;
  height: $arrow-height;
  line-height: $arrow-height;
  font-size: $arrow-height;
  margin-top: -$arrow-height / 2;

  &:before {
    content: "";
  }

  &:hover, &:focus {
    outline: none;
    color: $dark-grey;
  }
  span {
    display: none;
  }

  &.slick-disabled {
    opacity: 0;
  }
}

.slick-prev {
  left: 0
}

.slick-next {
  right: 0
}


.slick-dots {
  $size: 2rem;
  position: absolute;
  bottom: 2rem;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: $size / 2;
    width: $size / 2;
    margin: 0;
    margin-left: 0.4rem;
    padding: 0;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
    button {
      border: 0;
      background: transparent;
      display: block;
      height: $size / 2;
      width: $size / 2;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 0;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        &:before {
          opacity: 0.75;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "○";
        width: $size / 2;
        height: $size / 2;
        font-size: $size;
        line-height: $size / 2;
        text-align: center;
        color: $darker-grey;
        opacity: 0.5;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      content: "●";
      opacity: 1;
    }
  }
}


