$topnav-background: $dark-grey;
$topnav-link-color: $light-grey;
$topnav-link-hover-color: $light-grey-hover;
$topnav-triangle-size: 0.6em;

.toggle-cart {
  position: relative;

  .icon-shopping-checked {
    $size: em(9.3px, $navbar-font-size);
    @include transition(opacity 0.2s ease-in);
    color: $green;
    opacity: 1;
    font-size: $size;
    position: absolute;
    top: -0.8em;
    right: -0.3em;
  }

  &.disabled {
    .icon-shopping-checked {
      opacity: 0;
    }
  }
}

.nav {
  line-height: 1em;
  margin: 0;
  padding: 1.63em 0;

  li {
    padding: 0;
    position: relative;
    list-style: none;
    margin-left: 1.9em;
    display: inline;
    text-align: left;
    color: $topnav-link-color;
    text-transform: uppercase;

    &.nav__icon-link {
      margin-left: 2em;
      i:before {
        margin: 0;
      }
    }

    a {
      position: relative;
      cursor: pointer;
      color: $topnav-link-color;
      text-decoration: none;

      &:focus,
      &:hover,
      &.current {
        color: $topnav-link-hover-color;
      }

      & > i {
        vertical-align: text-top;
      }
    }

    .disabled {
      color: $topnav-link-hover-color;
      cursor: default;
    }
  }
}

ul.nav--main {
  float: right;

  li a.current:after,
  li span.current:after {
    position: absolute;
    bottom: -2.5em;
    left: 50%;
    margin-left: 1.4142 * -$topnav-triangle-size;
    content: " ";
    transform-origin: 0 0;
    transform: rotate(-45deg);
    border: $topnav-triangle-size solid $darker-grey;
    border-color: transparent transparent $darker-grey $darker-grey;
    box-shadow: -3px 3px 3px 0px rgba(0, 0, 0, 0.22);
  }
}

ul.nav--footer {
  padding: 0;

  li:first-child {
    margin-left: 0;
  }

  @include media('<tablet') {
    text-align: center;
  }
}
