@include media('<tablet') {
  .nav--main {
    padding: 0;
    padding-top: 0.2em;
    li {
      display: none;
    }
    li.nav__icon-link,
    li.dropdown {
      display: inline;
    }
    span.current::after {
      bottom: -1.9em !important;
    }
  }
  .nav--footer {
    display: none;
  }
}

#mobile-nav {
  display: none;

  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 100;

  padding-top: em($header-height-mobile, $navbar-font-size);
  padding-right: em(62px, $navbar-font-size);
  padding-left: $content-padding;
  background: rgba(51, 51, 51, 0.95);
}

.nav--collapsed {
  @include media('<tablet') {
    display: inline-block;
    width: 100%;
    padding: 0;

    li {
      display: block;
      float: none;
      margin-left: 0;
      &:not(:last-child) {
        margin-bottom: em(30px, $navbar-font-size);
      }
      color: $light-grey;
    }
  }
  @include media('<phone') {

  }
}

.hide-in-mobile {
  @include media('<tablet') {
    display: none;
  }
}
