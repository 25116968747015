@font-face {
  font-family: 'morgenwerk';
  src: url('../fonts/morgenwerk.eot?39607804');
  src: url('../fonts/morgenwerk.eot?39607804#iefix') format('embedded-opentype'),
       url('../fonts/morgenwerk.woff?39607804') format('woff'),
       url('../fonts/morgenwerk.ttf?39607804') format('truetype'),
       url('../fonts/morgenwerk.svg?39607804#morgenwerk') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'morgenwerk';
    src: url('../font/morgenwerk.svg?39607804#morgenwerk') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "morgenwerk";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-shopping-checked:before { content: '\e800'; } /* '' */
.icon-arrow-up-small:before { content: '\e801'; } /* '' */
.icon-arrow-scroll-up:before { content: '\e802'; } /* '' */
.icon-lookingglas:before { content: '\e803'; } /* '' */
.icon-circle-empty:before { content: '\e804'; } /* '' */
.icon-arrow-scroll-down:before { content: '\e805'; } /* '' */
.icon-arrow-right:before { content: '\e806'; } /* '' */
.icon-arrow-left:before { content: '\e807'; } /* '' */
.icon-arrow-down-small:before { content: '\e808'; } /* '' */
.icon-warenkorb-x:before { content: '\e809'; } /* '' */
.icon-shopping-bag:before { content: '\e80a'; } /* '' */
.icon-circle-full:before { content: '\e80b'; } /* '' */
.icon-instagram:before { content: '\e80c'; } /* '' */
.icon-scroll-down:before { content: '\e80d'; } /* '' */
.icon-scroll-up:before { content: '\e80e'; } /* '' */
.icon-twitter:before { content: '\e80f'; } /* '' */
.icon-menu:before { content: '\e810'; } /* '' */
.icon-angle-down:before { content: '\e811'; } /* '' */
.icon-scroll-left:before { content: '\e812'; } /* '' */
.icon-angle-up:before { content: '\e813'; } /* '' */
