html {
  box-sizing: border-box;
  font-size: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}


body {
  background-color: $darker-grey;
  .cc_container {
    .cc_btn:hover {
      background-color: $blue-hover;
    }
    .cc_btn {
      background-color: $blue;
      color: #fff;
      border-radius: 0;
    }
  }

  .cc_banner-wrapper {
    position: absolute;
    height: 50px;
    background-color: transparent;
    bottom: 0;
  }
  .cc_container {
    font-family: museo-sans, sans-serif;
    font-size: rem($static-font-size) !important;
    padding: 0em;
    color: $light-grey;

    .cc_message {
      margin: 0;
      padding: 0.8em;
      padding-left: 1em;
      font-size: 1em;
      line-height: 3em - (2 * 0.8em);
      color: $light-grey-hover;
    }
    .cc_more_info, .cc_more_info:hover, .cc_more_info:active, .cc_more_info:visited {
      color: $light-grey-hover;
      text-decoration: underline;
    }
    .cc_btn {
      margin: 0;
      width: auto;
      max-width: none;
      padding: 0 3em;
      font-size: 1em;
      line-height: 3em;
      text-transform: uppercase;
      &, &:hover {
        color: $white;
      }
    }

    @include media('<tablet') {
      .cc_btn {
        width: 100%;
        float: none;
      }
      .cc_message {
        line-height: 1.5em;
        text-align: center;
      }
    }
  }
}

#content {
  position: relative;
  margin-top: rem($header-height);
  @include media('<tablet') {
    margin-top: rem($header-height-mobile);
  }

  width:100%;
  z-index: 5;
  overflow: hidden;
}

.hidden {
  display: none;
}

.center {
  text-align: center;
}
