.feature-list {
  @extend .text-box;
  display: table;
  width: 100%;
  background-color: $light-grey;
  font-size: rem($technologies-font-size);
  padding: 0;

  ul {
    display: table-cell;
    box-sizing: border-box;
    margin: 0;
    padding: em(52px, $technologies-font-size) 0;
    width: 33%;
    border-left: rem(2px) solid $dark-grey;

    li {
      list-style: none;
    }

    &:first-child {
      border-color: $light-grey;
    }
  }
  h3 {
    font-size: em(16.8px, $technologies-font-size);
    line-height: em(33.9px, 19.8px);
    a {
      color: $darker-grey;
      &:hover {
        color: $dark-grey;
      }
    }
  }
  @include media('<81em') {
    font-size: 0.7rem;
  }

  @include media('<desktop') {
    font-size: 0.5rem;
  }

  @include media('<tablet') {
    font-size: 0.8rem;
    display: block;

    ul {
      display: block;
      width: 100%;
      border-left: none;
      border-top: rem(2px) solid $dark-grey;

      &:first-child {
        border: none;
      }
    }
  }
  @include media('<phone') {
    font-size: 0.7rem;
  }
}

.sketch {
  @extend .text-box;
  @extend .text-box--dark;
  font-size: rem($technologies-font-size);
  padding: 0 8.5%;
  padding-top: em(162px - 95px, $technologies-font-size);
  text-align: left;
  box-shadow: none;
  margin-bottom: 0.25em;

  background-image: url(#{$img-path}/technologies/sketch.png);
  background-image: url(#{$img-path}/technologies/sketch.svg);
  background-attachment: fixed;
  background-size: 100%;
  background-repeat: repeat-y;

  font-family: $base-font-family;
  h2 {
    font-size: em(33.9px, $technologies-font-size);
    font-family: $base-font-family;
    margin-bottom: em(13px, 33.9px);
    color: $lighter-grey;
  }

  p {
    margin-top: 0;
    width: calc(50% - #{em(16.8px, $technologies-font-size)});
    font-size: em(17px, $technologies-font-size);
    color: $lighter-grey;
  }

  p.left {
    float: left;
  }
  p.right {
    float: right;
  }

  .row {
    @include clearfix();
    padding-top: em(95px, $technologies-font-size);

    &:last-child {
      padding-bottom: em(95px, $technologies-font-size);
    }
  }

  @include media('<desktop') {
    padding-left: 5%;
    padding-right: 5%;
  }
  @include media('<tablet') {
    p {
      width: 100%;
    }
    p.left,
    p.right {
      float: none;
    }
  }
}
