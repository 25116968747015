$parallax-window-height: rem(500px);
$parallax-background-height: $parallax-window-height * 2;

.parallax {
  overflow: hidden;
  position: relative;
  width: 100%;
  font-size: $parallax-font-size;

  max-height: rem(850px);  // full-hd
  max-height: calc(100vh - #{rem($header-height)});
  @include media('<desktop') {
    max-height: 50vh;
  }

  i.icon-scroll-down,
  i.icon-scroll-up {
    display: none;
    z-index: 10;
    position: absolute;
    bottom: 4%;
    text-align: center;
    width: 100%;
    font-size: em(29px, $parallax-font-size);
    color: $black;
  }

  i.icon-scroll-up {
    cursor: pointer;
  }
}

.parallax--watches {
  max-height: calc(100vh - #{rem(273.4px)} - #{rem($header-height)});

  @include media('<desktop') {
    max-height: calc(100vh - #{rem(273.4px)} - #{rem($header-height-mobile)});
  }
}

.parallax__background {
  @include clearfix;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: $parallax-background-height;
  position: relative;
  top: - $parallax-window-height / 3;
  z-index: 0;
}

.parallax__content {
  @include clearfix;
  position: absolute;
  top: 21.5%;
  width: 40%;
  z-index: 5;
  left: $content-padding;
  color: white;
  text-shadow: $text-shadow;

  &, h2 {
    font-family: garamond-fb-display, serif;
  }

  @include media('<tablet') {
    width: auto;
    right: 10%;
    left: 10%;
  }

  h2 {
    font-size: em(55px, $parallax-font-size);
    text-transform: none;
    font-weight: 400;
  }

  p {
    font-size: em(40px, $parallax-font-size);
    line-height: 1.0;
    margin: 0;
    margin-top: 0.6em;
    font-weight: 300;
  }
}

.parallax__content--dark {
  text-shadow: none;

  &, h1, h2, p {
    color: $darker-grey;
  }
  h2 {
    // text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    font-weight: 900;
  }
  p {
    font-weight: 900;
    // text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  }

  i.icon-scroll-down,
  i.icon-scroll-up {
    color: $white;
  }
}

.parallax__content--vertical-top {
  top: 5%;
}
.parallax__content--vertical-bottom {
  top: auto;
  bottom: 20%;
}
.parallax__content--vertical-center {
  top: 45%;
}
.parallax__content--split {
  left: $content-padding;
  right: $content-padding;
  width: auto;
  h2 {
    float: left;
  }
  h2 + h2 {
    float: right;
  }
}
.parallax__content--right {
  left: auto;
  right: $content-padding;
}
.parallax__content--center {
  left: $content-padding;
  right: $content-padding;
  text-align: center;
  width: auto;

  @include media('>tablet') {
    padding-left: 20%;
    padding-right: 20%;
  }
}
.parallax__content--right {
  @include media('>=tablet') {
    left: auto;
  }
  right: 10%;
}

.parallax__model,
.parallax__footer {
  position: absolute;
  bottom: 2%;
  z-index: 5;
  font-size: em(11.3px, $parallax-font-size);

  left: 10%;
}
.parallax__footer {
  font-family: $base-font-family;
  font-size: em(20px, $parallax-font-size);

  right: 10%;
  font-weight: 400;

  a {
    color: $white;
    margin-left: 2em;
    &:hover {
      color: $lighter-grey;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}
.parallax__content--dark + .parallax__footer {
  font-size: em(20px, $parallax-font-size);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);

  a {
    color: $black;
    &:hover {
      color: $darker-grey;
    }
  }
}

.parallax__model--right {
  @include media('>=tablet') {
    left: auto;
    right: 10%;
  }
}
.parallax__model--dark {
  color: $darker-grey;
}

#home-1 {
  background-image: url(#{$img-path}/home/M1-3_187.jpg);
  @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
         only screen and (min--moz-device-pixel-ratio: 1.3),
         only screen and (-o-min-device-pixel-ratio: 1.3 / 1),
         only screen and (min-resolution: 125dpi),
         only screen and (min-resolution: 1.3dppx) {
    background-image: url(#{$img-path}/home/M1-3_187_2x.jpg);
  }
}

#home-2 {
  background-image: url(#{$img-path}/home/M2-2_312.jpg);
  @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
         only screen and (min--moz-device-pixel-ratio: 1.3),
         only screen and (-o-min-device-pixel-ratio: 1.3 / 1),
         only screen and (min-resolution: 125dpi),
         only screen and (min-resolution: 1.3dppx) {
    background-image: url(#{$img-path}/home/M2-2_312_2x.jpg);
  }
}

#home-3 {
  background-image: url(#{$img-path}/home/M2-1_285.jpg);
  @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
         only screen and (min--moz-device-pixel-ratio: 1.3),
         only screen and (-o-min-device-pixel-ratio: 1.3 / 1),
         only screen and (min-resolution: 125dpi),
         only screen and (min-resolution: 1.3dppx) {
    background-image: url(#{$img-path}/home/M2-1_285_2x.jpg);
  }
}
#home-4 {
  background-image: url(#{$img-path}/home/M3-1_47.jpg);
  @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
         only screen and (min--moz-device-pixel-ratio: 1.3),
         only screen and (-o-min-device-pixel-ratio: 1.3 / 1),
         only screen and (min-resolution: 125dpi),
         only screen and (min-resolution: 1.3dppx) {
    background-image: url(#{$img-path}/home/M3-1_47_2x.jpg);
  }
}
#home-5 {
  background-image: url(#{$img-path}/technologies/M2-2_332.jpg);
  @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
         only screen and (min--moz-device-pixel-ratio: 1.3),
         only screen and (-o-min-device-pixel-ratio: 1.3 / 1),
         only screen and (min-resolution: 125dpi),
         only screen and (min-resolution: 1.3dppx) {
    background-image: url(#{$img-path}/technologies/M2-2_332_2x.jpg);
  }
}
#watches-1{
  background-image: url(#{$img-path}/watches/M1-3_202.jpg);
}
