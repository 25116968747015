article.confirmation {
  text-align: center;
  font-size: rem($confirmation-font-size);
  width: 100%;
  padding-top: em(158.7px, $confirmation-font-size);
  padding-bottom: em(158.7px / 2, $confirmation-font-size);

  h1 {
    font-size: em(33.9px, $confirmation-font-size);
    margin-bottom: 0.5em;
  }

  .btn {
    font-size: em(11.3px, $confirmation-font-size);
    margin-top: em(76.6px, 11.3px);
    padding: 0.5em 10em;
  }
}
