.btn {
  @include transition(background 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in);

  display: inline-block;
  cursor: pointer;
  padding: 1em 2.3em;
  color: $darker-grey;
  background-color: transparent;
  border: $base-border;
  border-color: $dark-grey;
  font-size: 1em;

  &:hover {
    background: $dark-grey;
    color: white;
  }
}

.btn--inverse {
  background-color: $dark-grey;
  color: white;

  &:hover {
    background-color: darken($dark-grey, 5%);
  }
}

.btn--blue {
  background-color: $blue;
  color: $white;

  &:hover {
    background-color: darken($blue, 15%);
  }
}

.btn--red {
  cursor: default;

  &, &:hover {
    color: $white;
    border-color: $red;
    background-color: $red;
  }
}
